<template>
   <table>
        <thead>
            <tr>
                <th v-for="(item,key) in arrWeekOfMonth" :key="key">
                    <span>{{item}}</span>
                </th>
            </tr>
        </thead>
        <tbody v-html="tableCalendar">
        
        </tbody>
    </table>
</template>

<script>

export default {
    name: "CanlendarMonth",
     props: {
    arrWeekOfMonth: {
      type: Array,
      default: () => {
        return []
      },
    },
    tableCalendar: {
      type: String,
      default: '',
    },
   
  }
}
</script>
<style>
.tg_table_booking_system table tr th {
    width: 14%;
}
</style>